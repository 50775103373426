






























































import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

import insiderTipQuery from '@/graphql/InsiderTip.single.query.gql';
import addToUserFavoritesMutation from '@/graphql/me/favorite/AddToUserFavorites.mutation.gql';
import deleteFromUserFavoritesMutation from '@/graphql/me/favorite/DeleteFromUserFavorites.mutation.gql';

import BackHeader from '@/components/partials/BackHeader.vue';
import InsiderTipElement from '@/components/partials/InsiderTipElement.vue';
import TheHeartIcon from '@/components/icons/TheHeartIcon.vue';
import MsgEntityNotFound from '@/components/MsgEntityNotFound.vue';
import { prepareVariablesForSingleEntityQuery, redirectToSingleEntityRoute } from '@/helpers/graphqlHelper';

@Component({
  name: 'InsiderTipSinglePage',
  components: {
    MsgEntityNotFound,
    TheHeartIcon,
    BackHeader,
    InsiderTipElement,
  },
  metaInfo() {
    return {
      title: this.insiderTip?.title || 'Insider Tip',
    };
  },
  computed: {
    ...mapState(['isMobileScreenExclude768']),
  },
  apollo: {
    insiderTip: {
      query: insiderTipQuery,
      variables() {
        return this.queryVariables;
      },
      result({ data }: { data: any }) {
        const insiderTip = data?.insiderTip || null;

        if (this.queryVariables.onlyId && insiderTip?.id) {
          this.$router.replace(
            redirectToSingleEntityRoute(String(this.$route.name), insiderTip.id, this.queryVariables.slug, {
              params: this.$route.params,
              query: this.$route.query,
            })
          );
          return;
        }

        if (insiderTip) {
          this.insiderTip = insiderTip;
          this.favItems = this.insiderTip.elements
            .filter((e: any) => e.is_favorite_by_current_user)
            .map((e: any) => e.id);
        }

        this.insiderTipFetching = false;
      },
    },
  },
})
export default class InsiderTipSinglePage extends Vue {
  insiderTip: any = null;
  insiderTipFetching = true;
  favItems: any[] = [];
  favoriteChanging: any[] = [];

  get queryVariables() {
    return {
      ...prepareVariablesForSingleEntityQuery(this.$route.params),
      destinationSlug: this.$route.params.destinationSlug,
    };
  }

  addToFavoriteChanging(id: number) {
    this.favoriteChanging.push(id);
  }

  removeFromArray(arr: any[], value: any) {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  async addToFavorites(element: any) {
    try {
      this.addToFavoriteChanging(element.id);

      const res = await this.$apollo.mutate({
        mutation: addToUserFavoritesMutation,
        variables: {
          entities: [{ id: element.id, type: element.__typename }],
        },
      });

      if (res.data && res.data.addToUserFavorites) {
        this.favItems.push(element.id);
      }
    } catch (e) {
    } finally {
      this.removeFromArray(this.favoriteChanging, element.id);
    }
  }

  async removeFromFavorites(element: any) {
    try {
      this.addToFavoriteChanging(element.id);

      const res = await this.$apollo.mutate({
        mutation: deleteFromUserFavoritesMutation,
        variables: {
          entities: [{ id: element.id, type: element.__typename }],
        },
      });

      if (res.data && res.data.deleteFromUserFavorites) {
        this.removeFromArray(this.favItems, element.id);
      }
    } catch (e) {
    } finally {
      this.removeFromArray(this.favoriteChanging, element.id);
    }
  }
}
